.pageTitle__content .autotext-slider__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  margin: 0 auto; */
}

.pageTitle__content .autotext-slider__item {
  font: var(--font-h-m);
  color: inherit;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

  /* Более выраженная тень */
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  text-align: center;
  white-space: break-spaces;
  margin: 10px auto;
}

.pageTitle__content .autotext-slider__item--fade-in {
  opacity: 1;
}

.pageTitle__content .autotext-slider__item--fade-out {
  opacity: 0;
}

@media screen and (max-width: 1000px) {
  .pageTitle__content .autotext-slider__item {
    font: var(--font-h-m);
  }
}

@media screen and (max-width: 600px) {
  .pageTitle__content .autotext-slider__item {
    font: var(--font-h-s);
    white-space: pre-wrap;
  }

  .pageTitle__content .autotext-slider__container {
    margin-top: 15px;
  }
}
