.pageTitle__content {
  z-index: 3;
  margin: 168px auto 0;
  color: var(--color-black);
  text-align: center;
}

@media screen and (max-width: 600px) {
  .pageTitle__content {
    margin: 150px auto 0;
  }
}

.pageTitle__content.fixed {
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 2;
}

.pageTitle__content.fixed {
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  max-width: 70%;
  color: var(--color-white);
}

@media screen and (max-width: 600px) {
  .hero .pageTitle__content {
    max-width: 80%;
  }
}

.pageTitle__description {
  margin: 0 0 10px;
  font: var(--font-h-s);
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 600px) {
  .pageTitle__description {
    font-size: 0.75rem;
    letter-spacing: 0.7px;
  }
}

.pageTitle__title {
  margin: 0;
  font: var(--font-h-xl);
}

@media screen and (max-width: 600px) {
  .pageTitle__title {
    font-size: 2.3rem;
  }
}

.pageTitle__subtitle {
  margin: 10px auto 0;
  font: var(--font-h-s);
  white-space: break-spaces;
  line-height: 1.5;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1000px) {
  .pageTitle__subtitle {
    font: var(--font-l);
  }
}
